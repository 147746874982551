<template>
  <div class="the-upload-form">
    <div class="the-upload-form__title">
      <a-icon
        class="menu-toggle"
        type="menu"
        @click="toggleMenu"
      />
      <h1 class="ellipsis">{{ title }}</h1>
    </div>
    <a-form
      class="the-upload-form__form"
      @submit="onsubmit"
    >
      <a-upload
        :listType="isAcceptImage ? 'picture-card' : 'text'"
        :beforeUpload="beforeUpload"
        :accept="acceptedFormates"
        :fileList="fileList"
        :showUploadList="!isAcceptImage"
        class="the-upload-form__uploader-wrapper"
        @change="handleChange"
        @preview="showPreview"
        @remove="remove"
      >
        <div
          v-if="isAcceptImage"
          class="the-upload-form__image_uploader"
        >
          <div
            v-if="!fileList.length"
            :style="uploaderStyle"
            class="uploader__load"
          >
            <div>
              <a-icon type="plus" />
              <div class="ant-upload-text">{{ $t('base.placeholder.upload') }}</div>
            </div>
          </div>

          <div
            v-else-if="fileList.length"
            class="uploader-image"
          >
            <img
              :src="fileList[0].url"
              :width="width"
              :height="height"
              :alt="fileList[0].name"
            />
            <div class="uploader-image__overlay">
              <a-icon
                class="uploader-image__control"
                type="eye"
                @click.stop="showPreview"
              />
              <a-icon
                class="uploader-image__control"
                type="copy"
                @click.stop="copyUrl"
              />
              <a-icon
                class="uploader-image__control"
                type="delete"
                @click.stop="remove"
              />
            </div>
          </div>
        </div>
        <div
          v-else
          class="the-upload-form__attachment"
        >
          <a-button v-if="!isFileAttached">
            <a-icon type="upload" />
            {{ $t('base.placeholder.upload') }}
          </a-button>
        </div>
      </a-upload>
      <a-button
        type="primary"
        @click="onsubmit"
      >
        {{ customprops?.buttonLabel }}
      </a-button>
    </a-form>
    <a-modal
      :visible="!!activePreview"
      :footer="null"
      @cancel="closePreview"
    >
      <img
        style="width: 100%"
        :src="activePreview"
        alt=""
      />
    </a-modal>
  </div>
</template>

<script>
import store from '@/store';

import { bus, uploadFile, imageUrl, uniqueId, copyToBuffer } from '@/helpers';

export default {
  name: 'UploadForm',
  data() {
    return {
      value: null,
      activePreview: null,
      files: [],
    };
  },

  computed: {
    fullscreenIcon() {
      return this.fullscreenModal ? 'fullscreen-exit' : 'fullscreen';
    },
    pageConfig() {
      return store.state.activeSidebarItem || {};
    },
    customprops() {
      return this.pageConfig?.customprops;
    },
    title() {
      return this.pageConfig.title;
    },
    acceptedFormates() {
      return this.customprops?.formates ?? '*';
    },
    isAcceptImage() {
      return this.acceptedFormates === 'image/*';
    },
    isFileAttached() {
      return this.fileList?.length > 0;
    },

    width() {
      return 104;
    },
    height() {
      return 104;
    },
    uploaderStyle() {
      return `height: ${this.height - 2}px;`;
    },
    wrapperStyle() {
      return `width: ${this.width}px;`;
    },
    isEmpty() {
      return !this.fileList?.length;
    },
    fileList: {
      get() {
        if (this.value) {
          const sizeMultiplier =
            store.state.display.isRetina || store.state.display.isHighDensity ? 2 : 1;
          return [
            {
              uid: this.value.uid,
              name: this.value.name.replace(/^.*\//g, ''),
              url: imageUrl(
                this.value.name,
                {
                  width: this.width * sizeMultiplier,
                  height: this.height * sizeMultiplier,
                },
                this.customprops?.mediaURL,
              ),
              urlFull: imageUrl(this.value.name, {}, this.customprops?.mediaURL),
            },
          ];
        }

        return [];
      },
      set(value) {
        this.value = value[0];
        this.$emit('input', value);
      },
    },
  },
  methods: {
    async setFocus() {
      // browser only allows to open file dialog in response on user generated events
      // exception -- freshly created input element, so here the hack

      if (this.fileList?.length > 0) return;

      const element = document.createElement('input');
      element.type = 'file';
      element.onchange = this.onAutoInputChange;
      element.click();
    },

    onAutoInputChange({ target }) {
      const file = target.files[0];
      if (file) {
        file.uid = uniqueId(`fileupload`);
        this.handleChange({ fileList: [file] });
      } else {
        this.handleChange({ fileList: [] });
      }
    },
    toggleMenu() {
      bus.$emit('toggleMenu');
    },
    async onsubmit() {
      await uploadFile(this.files[0].originFileObj, this.customprops?.uploadURL);
    },
    showPreview() {
      if (this.isAcceptImage) {
        this.activePreview = this.fileList[0]?.urlFull;
      } else {
        window.open(this.fileList[0].urlFull, '_blank');
      }
    },
    closePreview() {
      this.activePreview = null;
    },
    remove() {
      this.fileList = null;
      this.handleChange({ fileList: [] });
    },
    copyUrl() {
      copyToBuffer(this.value);
    },
    beforeUpload() {
      return false;
    },
    async handleChange({ fileList }) {
      if (fileList.length > 0) {
        this.fileList = fileList;
        this.files = fileList;
      } else {
        this.$emit('remove', this.fileList[0].uid);
        this.remove();
      }
    },
  },
};
</script>

<style lang="scss">
.the-upload-form {
  padding-bottom: 40px;
  padding-left: 10px;
  padding-right: 10px;
  overflow-x: visible;

  &__title {
    position: relative;
    flex-basis: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    height: 42px;

    h1 {
      display: inline-block;
      font-size: 28px;
      font-weight: 700;
      vertical-align: sub;
      margin-bottom: 0;
      margin-right: 30px;
    }

    .menu-toggle {
      width: 30px;
      margin-right: 15px;
    }

    .ant-btn {
      position: relative;
      top: 3px;
    }
  }

  &__form {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  &__uploader-wrapper {
    padding-bottom: 24px;
  }

  &__attachment {
    width: 70vw;
  }
}

@media (min-width: $desktopBreakpoint) {
  .menu-toggle.anticon {
    display: none;
  }

  .the-upload-form {
    @include scrollbars();
    height: 100%;
    overflow-y: scroll;

    &.noscroll {
      overflow-y: hidden;
    }
  }

  .menu--vertical .the-upload-form {
    padding-left: 40px;
    padding-right: 26px;

    &.noscroll {
      padding-right: 34px;
    }
  }

  .menu--horizontal .the-upload-form {
    padding-left: 24px;
    padding-right: 24px;

    &.noscroll {
      padding-right: 32px;
    }
  }
}
</style>
