import gql from 'graphql-tag';

export default {
  fetchPolicy: 'network-only',
  query: gql`
    query LabelList {
      Label {
        list {
          id
          name
          apiName
          description
        }
      }
    }
  `,
  error(error) {
    this.emitError(this.$t('entity.error.get'), error.message);
  },
};
