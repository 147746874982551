<template>
  <div class="page-count-config">
    {{ $t(`filter.pageSize`) }}
    <a-input-number
      v-model="pageSizeLocal"
      :max="maxPageSize"
      :formatter="(val) => getMin(val)"
      :parser="(val) => getMin(val)"
    />
  </div>
</template>

<script>
import { debounce } from 'lodash';
import store from '@/store';

export default {
  name: 'PageCountConfig',

  props: {
    value: {
      type: [Number, String],
      default: 10,
    },
  },

  computed: {
    pageSizeLocal: {
      get() {
        return this.value;
      },
      set(val) {
        if (!val) {
          this.emitValue(10);
          return;
        }
        this.emitValue(val);
      },
    },
    maxPageSize() {
      return store.state.systemConfig?.maxRecords ?? 1000;
    },
  },
  mounted() {
    if (this.value > this.maxPageSize) {
      this.emitValue(this.value);
    }
  },

  methods: {
    getMin(val) {
      return Math.min(val, this.maxPageSize);
    },
    emitValue: debounce(function emitValue(val) {
      this.$emit('input', this.getMin(val));
    }, 1000),
  },
};
</script>

<style lang="scss">
.page-count-config {
  display: flex;
  align-items: center;
  padding-left: 20px;
  .ant-input-number {
    margin-left: 10px;
    margin-right: 8px;
    height: 24px;
    width: 90px;
    padding: 0 6px;
    text-align: center;
    input {
      height: 22px;
      text-align: center;
      padding: 0;
    }
  }
  .ant-input-number-handler-wrap {
    display: none;
  }
}
</style>
