import Vue from 'vue';
import { DESKTOP_BREAKPOINT } from '@/constants';
import { XHR } from '@/helpers/xhr';
import { storage } from '@/helpers/storage';
import { Cookies } from '@/helpers/cookies';
import { isHighDensityDisplay, isRetinaDisplay } from '@/helpers/display';
import AuthService from '@/services/AuthService';
import User from './User';

function getFormsDraftsFromStorage() {
  return storage.get('formsDrafts') || {};
}

const state = Vue.observable({
  appVersion: process.env.VUE_APP_FE_VERSION,
  user: new User(),
  windowWidth: 0,
  isDesktop: false,
  formsDrafts: getFormsDraftsFromStorage(),
  pages: [],
  lang: storage.get('lang'),
  darkTheme: storage.get('darkTheme') || false,
  menuMode: storage.get('menuMode') || 'vertical',
  activeSidebarItem: undefined,
  flatPagesList: undefined,
  display: {
    isHighDensity: isHighDensityDisplay(),
    isRetina: isRetinaDisplay(),
  },
  apolloClientOldApi: null,
  apolloClient: null,
  formConfigs: {},
  systemConfig: { maxRecords: 1000 },
  meta: null,
  authConfig: null,
  embedAutofocus: null,
  transProvider: null,
  keycloak: null,
  publishablesHistory: {},
  columnsWidthByEmbedLevel: { 0: [250] },
});

function saveFormsDraftsToStorage() {
  storage.set('formsDrafts', state.formsDrafts);
}

const mutations = {
  /**
   * Use only with await!
   */
  async sendCookieslessApolloQuery(options, apolloClient = state.apolloClientOldApi) {
    return new Promise((resolve, reject) => {
      const j_session = Cookies.get('J_SESSION');
      const j_refresh = Cookies.get('J_REFRESH');
      Cookies.delete('J_SESSION');
      Cookies.delete('J_REFRESH');

      apolloClient.query(options).then(
        (response) => {
          if (j_session) Cookies.set('J_SESSION', 1, j_session);
          if (j_refresh) Cookies.set('J_REFRESH', 1, j_refresh);
          resolve(response);
        },
        (error) => {
          if (j_session) Cookies.set('J_SESSION', 1, j_session);
          if (j_refresh) Cookies.set('J_REFRESH', 1, j_refresh);
          reject(error);
        },
      );
    });
  },

  storeFormDraft(type, id, data) {
    if (!type || !id || id === 'NOID') return;

    const draft = {
      changeTime: new Date(),
      data,
    };

    if (!state.formsDrafts[type]) Vue.set(state.formsDrafts, type, {});
    Vue.set(state.formsDrafts[type], id || '', draft);
    saveFormsDraftsToStorage();
  },

  getFormTypeDrafts(type) {
    return state.formsDrafts[type] || {};
  },

  getFormDraft(type, id = '') {
    return state.formsDrafts[type]?.[id] || null;
  },

  deleteFormDraft(type, id) {
    if (state.formsDrafts[type]) Vue.delete(state.formsDrafts[type], id);
    saveFormsDraftsToStorage();
  },

  deleteAllDrafts() {
    Vue.set(state, 'formsDrafts', {});
    saveFormsDraftsToStorage();
  },

  windowWidthChanged(width) {
    state.windowWidth = width;
    state.isDesktop = width >= DESKTOP_BREAKPOINT;
  },

  async logout(path = '/') {
    const isKeycloak = localStorage.getItem('KK_AUTH') === '1' && state.keycloak;

    if (isKeycloak) {
      const keycloakParams = state.authConfig.providers.find(
        (provider) => !!provider.keycloak,
      )?.keycloak;

      localStorage.removeItem('KK_AUTH');
      localStorage.removeItem('J_SESSION');
      localStorage.removeItem('J_REFRESH');
      Cookies.delete('J_SESSION');
      Cookies.delete('J_REFRESH');

      if (state.keycloak.authenticated) {
        state.keycloak.logout({
          redirectUri: process.env.VUE_APP_KK_LOGOUT_REDIRECT || keycloakParams.logoutRedirectUri,
        });
      } else {
        window.location.href = path;
      }
    } else {
      const url = state.authConfig?.logoutUrl || process.env.VUE_APP_LOGOUT_URL;
      await XHR.post(url, { absoluteUrl: true });

      localStorage.removeItem('KK_AUTH');
      localStorage.removeItem('J_SESSION');
      localStorage.removeItem('J_REFRESH');
      Cookies.delete('J_SESSION');
      Cookies.delete('J_REFRESH');
      window.location.href = path;
    }
  },

  isAuthorized() {
    return localStorage.getItem('J_SESSION') && localStorage.getItem('J_REFRESH');
  },

  updateToken() {
    if (localStorage.getItem('KK_AUTH') === '1' && state.keycloak) {
      return AuthService.updateToken();
    }

    return XHR.query('post', state.authConfig?.refreshUrl || process.env.VUE_APP_AUTHREFRESH_URL, {
      absoluteUrl: true,
      isRefreshTokenRequest: true,
      maxAttempts: 1,
    });
  },

  setMeta(meta) {
    state.meta = meta;
  },
  setSystemConfig(config) {
    state.systemConfig = config;
  },

  setAuthConfig(config) {
    state.authConfig = config;
  },

  setPageList(pages) {
    state.pages = pages;
  },

  switchDarkTheme(value) {
    state.darkTheme = value;
    storage.set('darkTheme', value);
  },

  switchMenuMode(value) {
    state.menuMode = value;
    storage.set('menuMode', value);
  },

  setActiveSidebarItem(activeItem) {
    state.activeSidebarItem = activeItem;
  },

  setFlatPagesList(pages) {
    state.flatPagesList = pages;
  },

  setApolloClients(oldApiClient, newApiClient) {
    state.apolloClientOldApi = oldApiClient;
    state.apolloClient = newApiClient;
  },

  setLang(lang) {
    state.lang = lang;
    storage.set('lang', lang);
  },

  setKeycloak(keycloak) {
    window.keycloak = keycloak;
    state.keycloak = keycloak;
  },

  setPublishableHistoryEntry(entity, id, version_id, data) {
    state.publishablesHistory[entity] = state.publishablesHistory[entity] ?? {};
    state.publishablesHistory[entity][id] = state.publishablesHistory[entity][id] ?? {};
    state.publishablesHistory[entity][id][version_id] = data;
  },

  getPublishableHistoryEntry(entity, id, version_id) {
    return state.publishablesHistory[entity]?.[id]?.[version_id];
  },

  clearPublishableHistoryEntry(entity) {
    state.publishablesHistory[entity] = {};
  },

  setColumsWidth(value, level) {
    state.columnsWidthByEmbedLevel[level] = value;
  },
};

export default {
  state,
  mutate: mutations,
};
