import { appSettings } from '@/AppSettings';
import { XHR } from '@/helpers/xhr';

export function mediaUrl(path, mediaURL) {
  let url = `${mediaURL ?? appSettings.get('mediaUrl')}${path}`;
  if (!url.startsWith('http')) url = `${window.location.origin}${url}`;
  return url;
}

export function imageUrl(path, params = {}, mediaURL = null) {
  if (!params.width && !params.height) {
    return mediaUrl(path, mediaURL);
  }

  let query = [`url=${mediaUrl(path, mediaURL)}`, `fit=${params.fit || 'cover'}`];

  if (params.width) {
    query.push(`w=${params.width}`);
  }

  if (params.height) {
    query.push(`h=${params.height}`);
  }

  // Make 'url' parameter the last one. Otherwise Ant uploader won't show preview image
  query = `?${query.reverse().join('&')}`;
  return `${appSettings.get('resizerUrl')}${query}`;
}

export async function uploadFile(file, uploadUrl) {
  const url = uploadUrl ?? appSettings.get('uploaderUrl');
  return new Promise((resolve, reject) => {
    const formData = new FormData();
    formData.append('file', file);

    XHR.post(url, {
      data: formData,
      dataType: 'formdata',
      absoluteUrl: true,
      withCredentials: false,
    }).then(resolve, (error) => {
      reject(new Error(error));
    });
  });
}
