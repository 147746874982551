<template>
  <div class="input-enum">
    <a-select
      v-model="input"
      :class="['input-enum__select', { readOnly }]"
      :placeholder="placeholder"
      :disabled="disabled || readOnly"
      :size="size"
      :mode="config?.mode"
      dropdownClassName="input-enum__dropdown"
      allowClear
    >
      <a-select-option
        v-for="option of config.enumOptions"
        :key="option"
      >
        {{ option }}
      </a-select-option>
    </a-select>
  </div>
</template>

<script>
export default {
  name: 'InputEnum',
  props: {
    config: {
      type: Object,
      required: true,
    },
    value: {
      type: String,
      default: undefined,
    },
    size: {
      type: String,
      default: 'default',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    input: {
      get() {
        return this.value || undefined;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
    placeholder() {
      return this.disabled || this.readOnly ? '' : this.$t('base.placeholder.select');
    },
  },
};
</script>

<style lang="scss">
.input-enum {
  position: relative;

  &__select {
    width: 100%;
  }

  &__dropdown.ant-select-dropdown {
    top: 32px !important;

    .ant-select-dropdown-menu {
      max-height: 5 * 32px;
    }
  }

  &.readOnly {
    color: inherit;

    .ant-select-selection {
      background: #fff;
      cursor: text;
      user-select: initial;
    }

    .ant-select-arrow {
      cursor: not-allowed;
    }
  }
}
</style>
