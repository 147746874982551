import gql from 'graphql-tag';

export default {
  fetchPolicy: 'network-only',
  query: gql`
    query getSummaryV2(
      $labelIds: [UUID]
      $releaseDateFrom: String
      $releaseDateTo: String
      $listensDateFrom: String
      $listensDateTo: String
      $datascreenFilters: [InputDatascreenFilters]
    ) {
      Release {
        getSummaryV2(
          labelIds: $labelIds
          releaseDateFrom: $releaseDateFrom
          releaseDateTo: $releaseDateTo
          listensDateFrom: $listensDateFrom
          listensDateTo: $listensDateTo
          datascreenFilters: $datascreenFilters
        ) {
          labelId
          releasesCount
          tracksCount
          listens {
            filter
            value
          }
        }
      }
    }
  `,
  error(error) {
    this.emitError(this.$t('entity.error.get'), error.message);
  },
};
