<template>
  <div
    v-if="!$apollo.queries.dashboardsConfig.loading"
    class="dashboard-page"
  >
    <div class="dashboard-page__title">
      <h1 class="ellipsis">{{ title }}</h1>
    </div>
    <div class="dashboard-page__list">
      <div
        v-for="(group, groupId) in enrichedData"
        :key="groupId"
      >
        <div class="dashboard-page__group-header">
          <h3 class="dashboard-page__group-title">{{ group?.title }}</h3>

          <div
            v-if="group?.params"
            class="dashboard-page__dashboard-filters"
          >
            <div
              v-for="(param, paramId) in group.params"
              :key="`group-param-${paramId}`"
              class="dashboard-page__dashboard-filter"
            >
              <p class="dashboard-page__dashboard-filter-label">{{ param.title }}:</p>
              <component
                :is="getFilterComponent(param)"
                v-model="filters[groupId]['params'][param.label]"
                class="dashboard-page__dashboard-filter-input"
                :placeholder="param.title"
                :config="param?.config"
              />
            </div>
          </div>
        </div>
        <div
          :style="groupStyles(group)"
          class="dashboard-page__group-body"
        >
          <div
            v-for="(dashboard, dashboardId) in group?.dashboards"
            :key="dashboardId"
          >
            <div
              v-if="dashboard?.params"
              class="dashboard-page__dashboard-filters"
            >
              <div
                v-for="(param, paramId) in dashboard.params"
                :key="`dashboard-param-${paramId}`"
                class="dashboard-page__dashboard-filter"
              >
                <template v-if="!param?.froumGroup">
                  <p class="dashboard-page__dashboard-filter-label">{{ param.title }}:</p>
                  <input-number
                    v-model="filters[groupId]['children'][dashboardId][param.label]"
                    :placeholder="param.title"
                    class="dashboard-page__dashboard-filter-input"
                  />
                </template>
              </div>
            </div>
            <component
              :is="graphComponent(dashboard?.type)"
              v-if="dashboard?.type"
              :options="dashboard?.options"
              :data="dashboard?.data"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { debounce } from 'lodash';
import store from '@/store';
import CONFIG_QUERY from '@/queries/config';
import { XHR, parseJson } from '@/helpers';
import InputNumber from '@/components/base/InputNumber';
import InputEnum from '@/components/base/InputEnum';

import {
  Bar,
  Line as LineChart,
  Doughnut,
  Pie,
  PolarArea,
  Radar,
  Bubble,
  Scatter,
} from 'vue-chartjs';

import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  ArcElement,
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
  RadialLinearScale,
} from 'chart.js';
import { drowLinePlugin, emptyDataPlugin } from './customPlugins';

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  BarElement,
  ArcElement,
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
  RadialLinearScale,
  drowLinePlugin,
  emptyDataPlugin,
);

export default {
  name: 'DashboardPage',
  components: {
    InputNumber,
    InputEnum,
    Bar,
    Doughnut,
    LineChart,
    Pie,
    PolarArea,
    Radar,
    Bubble,
    Scatter,
  },

  apollo: {
    dashboardsConfig: {
      ...CONFIG_QUERY,
      variables() {
        return {
          name: `${this.pageConfig.type}_${this.pageConfig.code}`,
        };
      },
      update({ table }) {
        return table.documents?.[0].data?.config ?? [];
      },
      error(error) {
        this.emitError(this.$t('app.error.mainConfig'), error.message);
      },
    },
  },
  data() {
    return {
      filters: [],
      enrichedData: [],
    };
  },

  computed: {
    pageConfig() {
      return store.state.activeSidebarItem || {};
    },
    title() {
      return this.dashboardsConfig?.title ?? this.pageConfig.code;
    },
  },
  watch: {
    dashboardsConfig: {
      immediate: true,
      handler(newVal) {
        if (newVal) {
          this.createFilters(newVal);
        }
      },
    },
    filters: {
      deep: true,
      handler: debounce(function updateFilters() {
        this.updateEnrichedData();
      }, 1000),
    },
  },
  methods: {
    getFilterComponent(param) {
      return (
        {
          number: 'input-number',
          string: 'input-string',
          enum: 'input-enum',
        }[param.type] ?? 'input-number'
      );
    },
    createFilters(config) {
      this.filters = (config?.groups ?? []).map((group) => ({
        params: this.paramsReducer(group?.params),
        children: group?.dashboards.map((dashboard) => this.paramsReducer(dashboard.params)),
      }));
    },
    paramsReducer(params = []) {
      return params.reduce((acc, value) => {
        acc[value.label] = value?.defaultValue;
        return acc;
      }, {});
    },
    async updateEnrichedData() {
      this.enrichedData = this.dashboardsConfig.groups.map((groupConfig, groupIdx) => {
        const res = { ...groupConfig };
        Promise.all(
          groupConfig.dashboards.map(
            (el, elIdx) =>
              new Promise((resolve) => {
                const groupFilters = this.filters?.[groupIdx]?.params;
                const elFilters = this.filters?.[groupIdx]?.children?.[elIdx];
                if (el?.restUrl) {
                  const url = this.formatParams(el.restUrl, { ...groupFilters, ...elFilters });

                  XHR.get(url, { absoluteUrl: true }).then(
                    (response) => {
                      const data = parseJson(response);
                      resolve({ ...el, ...data });
                    },
                    () => {
                      this.emitError(`Failed to load data from ${url}`);

                      resolve({});
                    },
                  );
                } else {
                  resolve(el);
                }
              }),
          ),
        ).then((results) => {
          res.dashboards = results;
        });

        return res;
      });
    },
    formatParams(url, params) {
      if (!params || !Object.values(params).some((value) => value)) {
        return url;
      }
      return `${url}?${Object.entries(params)
        // eslint-disable-next-line no-unused-vars
        .filter(([_key, value]) => !!value)
        .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
        .join('&')}`;
    },
    graphComponent(type) {
      return (
        {
          bar: 'bar',
          line: 'line-chart',
          doughnut: 'doughnut',
          pie: 'pie',
          polarArea: 'polar-area',
          radar: 'radar',
          bubble: 'bubble',
          scatter: 'scatter',
        }[type] ?? 'bar'
      );
    },
    graphStyles(graphConfig) {
      return {
        width: `${graphConfig?.width ?? '100'}%`,
      };
    },
    groupStyles(groupConfig) {
      return {
        gridTemplateColumns: `repeat(${groupConfig?.dashboardsOnRow ?? '1'}, minmax(0, 1fr)`,
      };
    },
  },
};
</script>

<style lang="scss">
.dashboard-page {
  padding-bottom: 40px;
  padding-left: 10px;
  padding-right: 10px;
  overflow-x: visible;

  &__title {
    position: relative;
    flex-basis: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    height: 42px;

    h1 {
      display: inline-block;
      font-size: 28px;
      font-weight: 700;
      vertical-align: sub;
      margin-bottom: 0;
      margin-right: 30px;
    }
  }

  &__list {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  &__group {
    &-title {
      text-align: center;
    }
    &-body {
      display: grid;
      gap: 20px;
    }
  }

  &__dashboard {
    &-filters {
      display: flex;
      flex-wrap: wrap;
      gap: 10px 20px;
    }
    &-filter {
      display: flex;
      gap: 10px;
      align-items: center;

      .dashboard-page__dashboard-filter-input,
      &-label {
        white-space: nowrap;
        margin-bottom: 0;
      }
      .dashboard-page__dashboard-filter-input {
        min-width: 100px;
      }
    }
  }
}

@media (min-width: $desktopBreakpoint) {
  .dashboard-page {
    @include scrollbars();
    height: 100%;
    overflow-y: scroll;

    &.noscroll {
      overflow-y: hidden;
    }
  }

  .menu--vertical .dashboard-page {
    padding-left: 40px;
    padding-right: 26px;

    &.noscroll {
      padding-right: 34px;
    }
  }

  .menu--horizontal .dashboard-page {
    padding-left: 24px;
    padding-right: 24px;

    &.noscroll {
      padding-right: 32px;
    }
  }
}

@media (max-width: $tabletBreakpoint) {
  .dashboard-page {
    .dashboard-page__group-body {
      grid-template-columns: minmax(0, 1fr) !important;
    }
  }
}
</style>
