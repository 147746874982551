export const drowLinePlugin = {
  id: 'drawLines',
  // WIP: change min and max values by linesAt
  // beforeRender: (chart) => {
  //   const optionsHash = JSON.stringify(chart.options);
  //   const dataHash = JSON.stringify(chart.data);

  //   const optionsChanged = chart._lastOptionsHash !== optionsHash;
  //   const dataChanged = chart._lastDataHash !== dataHash;

  //   if (!optionsChanged && !dataChanged) {
  //     return; // Если данные и опции не изменились, пропускаем обновление
  //   }

  //   const linesAt = chart?.config?.options?.linesAtYAxis;
  //   if (linesAt && linesAt.length) {
  //     const yScale = chart.scales.y;
  //     const newMin = Math.min(...linesAt, yScale.min);
  //     const newMax = Math.max(...linesAt, yScale.max);

  //     const scaleChanged = yScale.options.min !== newMin || yScale.options.max !== newMax;

  //     if (scaleChanged) {
  //       yScale.options.min = newMin;
  //       yScale.options.max = newMax;
  //     }
  //   }

  //   chart._lastOptionsHash = optionsHash;
  //   chart._lastDataHash = dataHash;
  // },
  afterRender: (chart, args, options) => {
    const { linesAtYAxis = [] } = options;

    if (linesAtYAxis.length) {
      const yAxe = chart.scales.y;
      const ctxPlugin = chart.ctx;
      const xAxe = chart.scales.x;
      linesAtYAxis.forEach((line) => {
        ctxPlugin.strokeStyle = line?.color ?? 'red';
        if (line.value < yAxe?.max && line.value > yAxe?.min) {
          ctxPlugin.beginPath();
          const lineAt = yAxe.getPixelForValue(line.value);
          ctxPlugin.moveTo(xAxe.left, lineAt);
          ctxPlugin.lineTo(xAxe.right, lineAt);
          ctxPlugin.stroke();
        }
      });
    }
  },
};

export const emptyDataPlugin = {
  id: 'emptyData',
  afterDraw(chart, _args, options) {
    const { datasets = [] } = chart.data;
    const {
      emptyText = 'Данные не найдены',
      textColor = 'red',
      textFont = '12px Arial',
      borderColor,
      borderWidth,
      borderDash,
      borderDashOffset,
    } = options;
    const filteredDataset = datasets.filter((el) => el.data.length);
    const hasData = filteredDataset.length > 0;

    if (!hasData) {
      const {
        ctx,
        chartArea: { left, top, width, height, right, bottom },
      } = chart;

      const centerX = (left + right) / 2;
      const centerY = (top + bottom) / 2;
      ctx.save();

      ctx.textAlign = 'center';
      ctx.font = textFont;
      ctx.fillStyle = textColor;
      ctx.fillText(emptyText, centerX, centerY);

      if (borderColor && borderWidth) {
        ctx.strokeStyle = borderColor;
        ctx.lineWidth = borderWidth;
        ctx.setLineDash(borderDash || []);
        ctx.lineDashOffset = borderDashOffset;
        ctx.strokeRect(left, top, width, height);
      }
      ctx.restore();
    }
  },
};
