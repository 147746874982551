<template>
  <div class="fields-config search-config">
    <a-input
      v-model="searchValue"
      class="fields-config__input"
      :size="controlsSize"
      :placeholder="$t(`base.placeholder.search`)"
      @keyup.enter="$emit('applyParams')"
      @keydown="filterKeydown"
      @paste="handlePaste"
    >
      <template
        #addonAfter
        v-if="options.length > 1"
      >
        <a-popover
          v-model="showConfig"
          trigger="click"
          placement="bottomRight"
        >
          <a-icon type="file-search" />
          <template #content>
            <div class="fields-config__popover">
              <div class="filter__popover">
                <div
                  v-for="field in options"
                  :key="field.value"
                  :class="['filter__titles', { active: isFieldSelected(field) }]"
                  @click="() => selectField(field)"
                >
                  <span>{{ field.label || field.value }}</span>
                </div>
              </div>
            </div>
          </template>
        </a-popover>
      </template>
    </a-input>
  </div>
</template>

<script>
import configFormMixin from './configFormMixin';

export default {
  name: 'SearchConfig',
  mixins: [configFormMixin],
  props: {
    value: {
      type: String,
      required: true,
    },
    search: {
      type: String,
      required: true,
    },
    controlsSize: {
      type: String,
      default: 'large',
    },
  },
  data() {
    return {
      showConfig: false,
      selectedFieldType: 'text',
    };
  },
  computed: {
    searchValue: {
      get() {
        return this.search;
      },
      set(value) {
        this.$emit('setSearchValue', value);
      },
    },
    selectedField: {
      get() {
        return this.value;
      },
      set(value) {
        this.showConfig = false;
        this.$emit('input', value);
      },
    },
    options() {
      return this.configLayout.searchField.options(this.entityFields, this.entityType);
    },
  },
  watch: {
    selectedField() {
      this.updateSelectedFieldType();
    },
  },
  mounted() {
    this.updateSelectedFieldType();
  },
  methods: {
    selectField(field) {
      if (this.selectedFieldType !== this.getFieldType(field)) {
        this.$emit('setSearchValue', '');
      }
      this.selectedField = field.value;
    },
    isFieldSelected({ value }) {
      return this.selectedField === value;
    },
    updateSelectedFieldType() {
      const field = this.options.find(this.isFieldSelected);
      if (this.selectedFieldType !== this.getFieldType(field)) {
        this.selectedFieldType = this.getFieldType(field);
      }
    },
    getFieldType(field) {
      return (
        {
          integer: 'number',
          number: 'number',
          real_number: 'number',
          string: 'text',
          text: 'text',
        }[field?.renderer] ?? 'text'
      );
    },
    filterKeydown(e) {
      if (this.selectedFieldType === 'number') {
        if (
          e.key === 'Backspace' ||
          e.key === 'Delete' ||
          e.key === 'Tab' ||
          e.key === 'Escape' ||
          e.key === 'Enter' ||
          e.ctrlKey ||
          e.metaKey ||
          e.key === 'ArrowLeft' ||
          e.key === 'ArrowRight'
        ) {
          return;
        }

        // Проверяем, чтобы ввод был числовым (не допускаем символы '+', '-', '.', 'e')
        if (!/^\d$/.test(e.key)) {
          e.preventDefault();
        }
      }
    },
    handlePaste(e) {
      if (this.selectedFieldType === 'number') {
        e.preventDefault(); // Предотвращаем стандартную вставку

        const pastedData = e.clipboardData.getData('Text'); // Получаем данные из буфера обмена
        const filteredData = pastedData.replace(/\D/g, ''); // Оставляем только числа

        // Вставляем отфильтрованные данные в поле
        const newValue =
          this.searchValue.slice(0, e.target.selectionStart) +
          filteredData +
          this.searchValue.slice(e.target.selectionEnd);

        this.searchValue = newValue;

        // Устанавливаем позицию курсора
        this.$nextTick(() => {
          const input = e.target;
          const caretPosition = e.target.selectionStart + filteredData.length;
          input.setSelectionRange(caretPosition, caretPosition);
        });
      }
    },
  },
};
</script>

<style lang="scss">
.search-config {
  .anticon {
    padding: 10px 14px 11px;
    font-size: 17px;
  }
  .ant-input-group-addon {
    padding: 0;
    cursor: pointer;
  }
  .ant-input::-webkit-inner-spin-button {
    display: none;
  }
}

.ant-input-group-addon {
  background: #fff;
}
</style>
